
const SCREEN_SIZE = {
    MOBILE_S: 320,
    MOBILE_M: 375,
    MOBILE_L: 425,
    TABLET: 768,
    LAPTOP: 1024,
    LAPTOP_L: 1440,
}

export {
    SCREEN_SIZE
}
