import React from 'react';

import './Question.scss';

const Question = ({
    question,
    answear
}) => {

    return (
        <div className="question-wrapper">
            <h3 className="question">{question}</h3>
            <p className="answear">{answear}</p>
        </div>
    )
}

export default Question;
