import React from 'react';
import { Link } from 'react-router-dom';

// Constants
import { MENU_ITEMS } from 'constants/menuConstants';

// MUI
import { IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import './Footer.scss';

const Footer = () => {

    return (
        <div className="footer-wrapper">
            <div className="menu-items-container">
                {MENU_ITEMS.map((item, index) =>
                    <Link className="menu-item" to={item.ROUTE} key={index}>
                        <span>{item.NAME}</span>
                    </Link>
                )}
            </div>

            <div className="social-items-container">
                <IconButton aria-label="open-social" size="small" className="social-button" onClick={() => console.log(45)}>
                    <FacebookIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="open-social" size="small" className="social-button" onClick={() => console.log(45)}>
                    <InstagramIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="open-social" size="small" className="social-button" onClick={() => console.log(45)}>
                    <YouTubeIcon fontSize="small" />
                </IconButton>
                <IconButton aria-label="open-social" size="small" className="social-button" onClick={() => console.log(45)}>
                    <LinkedInIcon fontSize="small" />
                </IconButton>
            </div>

            <div className="rights">© All rights reserved.</div>
        </div>
    )
};

export default Footer;
