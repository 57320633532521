import { Routes, Route } from 'react-router-dom'

import HomePage from 'components/HomePage';
import ShopsPage from 'components/ShopsPage';
import OnlineShopPage from 'components/OnlineShopPage';
import AboutUsPage from 'components/AboutUsPage';
import ForumPage from 'components/ForumPage/ForumPage';
import ContactsPage from 'components/ContactsPage/ContactsPage';
import SingupPage from 'components/SignupPage/SignupPage';
import LoginPage from 'components/LoginPage/LoginPage';

import GlobalNavigation from 'components/GlobalNavigation';

// Constants
import { ROUTE } from 'constants/navigationConstants';

import './App.scss';

function App() {
    return (
        <div className="App">
            <GlobalNavigation>
                <Routes>
                    <Route path={ROUTE.HOME_PAGE} element={<HomePage />} />
                    <Route path={ROUTE.SHOPS_PAGE} element={<ShopsPage />} />
                    <Route path={ROUTE.ONLINE_SHOP_PAGE} element={<OnlineShopPage />} />
                    <Route path={ROUTE.FOR_US_PAGE} element={<AboutUsPage />} />
                    <Route path={ROUTE.FORUM_PAGE} element={<ForumPage />} />
                    <Route path={ROUTE.CONTACTS_PAGE} element={<ContactsPage />} />
                    <Route path={ROUTE.SIGNUP_PAGE} element={<SingupPage />} />
                    <Route path={ROUTE.LOGIN_PAGE} element={<LoginPage />} />
                </Routes>
            </GlobalNavigation>
        </div>
    );
}

export default App;
