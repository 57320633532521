import { ROUTE } from './navigationConstants';

const MENU_ITEMS = [
    {
        NAME: 'Поръчай онлайн',
        ROUTE: ROUTE.ONLINE_SHOP_PAGE
    },
    {
        NAME: 'Полезно',
        ROUTE: ROUTE.FORUM_PAGE
    },
    {
        NAME: 'За Нас',
        ROUTE: ROUTE.FOR_US_PAGE
    },
    {
        NAME: 'Контакти',
        ROUTE: ROUTE.CONTACTS_PAGE
    }
]

export {
    MENU_ITEMS
}
