import React from 'react';

import './CustomInformationBanner.scss';

const CustomInformationBanner = ({
    image,
    text
}) => {

    return (
        <div className="custom-information-wrapper">
            <div className="custom-information-image">
                <img alt="information-banner" src={image} />
            </div>
            <div className="custom-information-text">{text}</div>
        </div>
    )
}

export default CustomInformationBanner;
