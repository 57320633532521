import React from 'react';

// Components
import CustomButton from 'components/Shared/CustomButton';

// MUI 
import StarIcon from '@mui/icons-material/Star';

//Assets
import HomePageFirstImage from 'assets/HomePageFirstImage.webp';

import './MainInformationBlock.scss';

const MainInformationBlock = () => {

    return (
        <div className="main-block-wrapper">
            <div className="information-wrapper">
                <h1 className="title-container">
                    Пазарувайте онлайн сублиматори за оксалова киселина в <span className="brand-name">Kabo</span>
                </h1>
                <h2 className="subtitle-container">
                    Научете защо грижата за пчелите е здравословна и защо сублиматорът с оксалова киселина е добър за пчелите
                </h2>
                <CustomButton className="connect-button" variant="contained">Свържи се</CustomButton>
                <div className="review-wrapper">
                    <div className="stars-container">
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                    </div>
                    <div className="text-container">Висококачествени сублиматори</div>
                    <div className="text-container">Експертни ресурси</div>
                </div>
            </div>
            <div className="visuals-wrapper">
                <img src={HomePageFirstImage} alt="Home Page First" />
                <rect className="dot-grid"></rect>
                <div className="gray-grid"></div>
            </div>
        </div>
    )
}

export default MainInformationBlock;
