import React from 'react';

const ShopsPage = ({
    dom
}) => {
    return (
        <div>ShopsPage</div>
    )
}

export default ShopsPage;
