const SUBLIMATOR_QUESTIONS = [
    {
        QUESTION: 'Как работи сублиматорът с оксалова киселина?',
        ANSWEAR: 'Сублиматорът с оксалова киселина работи чрез изпаряване на оксалова киселина, която ефективно третира пчелите за акари вароа, без да уврежда пчелите.'
    },
    {
        QUESTION: 'Мога ли да използвам сублиматора с оксалова киселина за други вредители?',
        ANSWEAR: 'Сублиматорът с оксалова киселина е специално предназначен за третиране на акари вароа. Може да не е ефективен срещу други вредители.'
    },
    {
        QUESTION: 'Колко често трябва да използвам сублиматора с оксалова киселина?',
        ANSWEAR: 'Честотата на употреба зависи от тежестта на заразата с вароа. Препоръчително е да следвате инструкциите, предоставени със сублиматора, или да се консултирате с експерт по пчеларство.'
    }
]

export {
    SUBLIMATOR_QUESTIONS
}
