import React from 'react';

// Components
import { useScreenSize } from 'utils/hooks';

import './SecondaryInformationBlock.scss';

const SecondaryInformationBlock = ({
    componentImage,
    title,
    text,
    isImageRight
}) => {

    const { isLaptop } = useScreenSize();

    return (
        <div className={`secondary-block-wrapper ${isImageRight ? 'right-wrapper' : ''}`}>
            {!isImageRight && !isLaptop &&
                < div className="visuals-wrapper-left">
                    <img src={componentImage} alt="Home page"/>
                </div>
            }
            <div className="information-wrapper">
                <h1 className="title-container">
                    {title}
                </h1>
                <div className="subtitle-container">
                    {text}
                </div>
            </div>
            {(isImageRight || isLaptop) &&
                < div className={isImageRight ? "visuals-wrapper-right" : "visuals-wrapper-left"}>
                    <img src={componentImage} alt="Home page"/>
                </div>
            }
        </div >
    )
}

export default SecondaryInformationBlock;
