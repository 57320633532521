import React from 'react';

// Components
import MainInformationBlock from './MainInformationBlock';
import SecondaryInformationBlock from './SecondaryInformationBlock/SecondaryInformationBlock';
import CommentBanner from 'components/Shared/CommentBanner/CommentBanner';
import ContactBanner from './ContactBanner/ContactBanner';

//Assets
import HomePageSecondImage from 'assets/HomePageSecondImage.jpg';
import HomePageThirdImage from 'assets/HomePageThirdImage.jpg';
import HomePageFourthImage from 'assets/HomePageFourthImage.jpg';

import './HomePage.scss';

const HomePage = ({
    dom
}) => {
    return (
        <div className="home-page-wrapper">
            <MainInformationBlock />
            <SecondaryInformationBlock componentImage={HomePageSecondImage} title={'Подобрете здравето на пчелите'} text={'Сублиматорът с оксалова киселина е мощен инструмент, който помага за подобряване на здравето на пчелите. Използвайки този сублиматор, можете ефективно да третирате вашите пчели от вароа акари, често срещана заплаха за пчелните колонии. Това третиране насърчава по-силни и здрави пчели, като гарантира цялостното благосъстояние на вашия кошер.'}/>
            <SecondaryInformationBlock isImageRight={true} componentImage={HomePageThirdImage} title={'Лесно и удобно пазаруване'} text={`В Kabo предлагаме безпроблемно онлайн пазаруване. Можете лесно да разглеждате и купувате сублиматори с оксалова киселина от комфорта на собствения си дом. Само с няколко щраквания ще имате основния инструмент, от който се нуждаете, за да се грижите за вашите пчели и да защитите здравето им.`}/>
            <SecondaryInformationBlock componentImage={HomePageFourthImage} title={'Експертни съвети и ресурси'} text={'Предоставяме ценна информация на нашия уебсайт за важността на грижата за пчелите и ползите от използването на сублиматора с оксалова киселина. Нашите експертни ресурси ще ви преведат през процеса на пчеларство и ще ви помогнат да вземете информирани решения, за да гарантирате благосъстоянието на вашите пчели.'}/>
            <CommentBanner comment={`"Този ​​сублиматор значително подобри здравето на моите пчели."`} name="Калин Трифонов"/>
            <ContactBanner />
        </div>
    )
}

export default HomePage;
