import React from 'react';

import './CommentBanner.scss';

const CommentBanner = ({
    comment,
    name
}) => {

    return (
        <div className="comment-banner-wrapper">
            <h1 className="comment-container">{comment}</h1>
            <div className="name-container">{name}</div>
        </div>
    )
}

export default CommentBanner;
