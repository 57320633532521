import React from 'react';

const ContactsPage = () => {

    return (
        <div> ContactsPage </div>
    )
}

export default ContactsPage;
