import React from 'react';

// Components
import CustomButton from 'components/Shared/CustomButton';
import CustomSwiper from 'components/Shared/CustomSwiper';

// MUI 
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import StarIcon from '@mui/icons-material/Star';

//Assets
import ShopPageFirst from 'assets/ShopPageFirst.jpg';
import ShopPageSecond from 'assets/ShopPageSecond.jpg';

import './ProductOverview.scss';

const ProductOverview = () => {
    return (
        <div className="provider-overview-wrapper">
            <div className="information-wrapper">
                <h1 className="title-container">
                    Пазарувайте онлайн сублиматори за оксалова киселина
                </h1>
                <div className="price-container">
                    <div>120 лв.</div>
                    <div className="stars-container">
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                        <StarIcon className="stars" style={{ color: "#FACC15" }} />
                    </div>
                </div>
                <h2 className="subtitle-container">
                    Сублиматорът за оксалова киселина е задължителен инструмент за пчеларите. Той ефективно третира пчелите срещу вароа акари, насърчавайки по-силни и здрави колонии. В Kabo предлагаме висококачествени сублиматори и експертни ресурси, за да ви помогнем да се грижите за вашите пчели и да защитите тяхното здраве.
                </h2>
                <div className="review-wrapper">
                    <div className="text-wrapper">
                        <TaskAltIcon />
                        <div className="text-container">
                            Подобрете здравето на пчелите
                        </div>
                    </div>
                    <div className="text-wrapper">
                        <TaskAltIcon />
                        <div className="text-container">
                            Лесно онлайн пазаруване
                        </div>
                    </div>
                    <div className="text-wrapper">
                        <TaskAltIcon />
                        <div className="text-container">
                            Експертни пчеларски ресурси
                        </div>
                    </div>
                </div>
                <CustomButton className="connect-button" variant="contained" size="medium">Купи сега</CustomButton>
            </div>
            <div className="visual-wrapper">
                <CustomSwiper
                    images={[ShopPageFirst, ShopPageSecond]}
                />
            </div>
        </div>
    )
};

export default ProductOverview;
