
const ROUTE = {
    HOME_PAGE: '/',
    SHOPS_PAGE: '/shops',
    ONLINE_SHOP_PAGE: '/online-shop',
    FOR_US_PAGE: '/about',
    FORUM_PAGE: '/forum',
    CONTACTS_PAGE: '/contacts',
    SIGNUP_PAGE: '/signup',
    LOGIN_PAGE: '/login',
}

export {
    ROUTE
}
