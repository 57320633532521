// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

const firebaseConfig = {
    apiKey: "AIzaSyCShngLyucjBwYBmkYFrq1u_sw9OEPs5Gk",
    authDomain: "kabobee-efe14.firebaseapp.com",
    projectId: "kabobee-efe14",
    storageBucket: "kabobee-efe14.appspot.com",
    messagingSenderId: "324086362530",
    appId: "1:324086362530:web:bb86ad19d13eaa0d6702fb",
    measurementId: "G-EEWGY735KT"
  };
 
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
