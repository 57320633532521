import React from 'react';

// MUI
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import AddCircleIcon from '@mui/icons-material/AddCircle';

// Components
import CustomFeature from 'components/Shared/CustomFeature';

import './ProductFeatures.scss';

const ProductFeatures = () => {

    return (
        <div className="product-features-wrapper">
            <CustomFeature Icon={CheckCircleIcon} title="Подобрете здравето на пчелите" text="Сублиматорът с оксалова киселина насърчава по-силни и по-здрави пчели, като гарантира цялостното благосъстояние на вашия кошер."/>
            <CustomFeature Icon={SwapHorizontalCircleIcon} title="Лесно онлайн пазаруване" text="Пазарувайте сублиматори за оксалова киселина от уюта на собствения си дом с нашето безпроблемно онлайн пазаруване."/>
            <CustomFeature Icon={PlayCircleFilledIcon} title="Експертни пчеларски ресурси" text="Достъп до ценна информация и ресурси, които да ви напътстват през процеса на пчеларство и да вземате информирани решения за вашите пчели."/>
            <CustomFeature Icon={AddCircleIcon} title="Издръжлив и надежден" text="Нашите сублиматори са изработени от висококачествени материали и са проектирани да издържат, осигурявайки ви дълготрайно решение за пчеларството."/>
        </div>
    )
}

export default ProductFeatures;
