import React from 'react';

// Components
import ProductOverview from './ProductOverview';
import ProductDetails from './ProductDetails';
import ProductFeatures from './ProductFeatures';
import CommentBanner from 'components/Shared/CommentBanner/CommentBanner';
import FrequentlyAskedQuestions from 'components/FrequentlyAskedQuestions';

// Constants
import { SUBLIMATOR_QUESTIONS } from 'constants/questionsConstants';

import './OnlineShopPage.scss';

const OnlineShopPage = () => {

    return (
        <div className="shop-page-wrapper">
            <ProductOverview />
            <ProductDetails />
            <ProductFeatures />
            <CommentBanner name="Калин Трифонов" comment={`"Сублиматорът за оксалова киселина от Kabo е основен инструмент за всеки пчелар. Той значително подобри здравето на моите пчели и направи пчеларството по-лесно от всякога."`} />
            <FrequentlyAskedQuestions questions={SUBLIMATOR_QUESTIONS}/>
        </div>
    )
}

export default OnlineShopPage;
