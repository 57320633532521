// useScreenSize.js
import { useState, useEffect } from 'react';
import { SCREEN_SIZE } from 'constants/screenSizeConstants';

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState({
        isMobileS: window.innerWidth <= SCREEN_SIZE.MOBILE_S,
        isMobileM: window.innerWidth <= SCREEN_SIZE.MOBILE_M,
        isMobileL: window.innerWidth <= SCREEN_SIZE.MOBILE_L,
        isTablet: window.innerWidth <= SCREEN_SIZE.TABLET,
        isLaptop: window.innerWidth <= SCREEN_SIZE.LAPTOP,
        isLaptopL: window.innerWidth <= SCREEN_SIZE.LAPTOP_L,
    });

    useEffect(() => {
        const handleResize = () => {
            
            setScreenSize({
                isMobileS: window.innerWidth <= SCREEN_SIZE.MOBILE_S,
                isMobileM: window.innerWidth <= SCREEN_SIZE.MOBILE_M,
                isMobileL: window.innerWidth <= SCREEN_SIZE.MOBILE_L,
                isTablet: window.innerWidth <= SCREEN_SIZE.TABLET,
                isLaptop: window.innerWidth <= SCREEN_SIZE.LAPTOP,
                isLaptopL: window.innerWidth <= SCREEN_SIZE.LAPTOP_L,
            });
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return screenSize;
};

export {
    useScreenSize
}
