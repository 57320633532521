
// MUI
import Button from '@mui/material/Button';

import './CustomButton.scss';

const CustomButton = ({
    handleClick,
    children,
    size = "small",
    ...props
}) => {

    return (
        <Button {...props} className={`custom-button-${size} ${size}`} onClick={handleClick}>{children}</Button>
    )
}

export default CustomButton;
