import React from 'react';

// Components
import Question from 'components/Shared/Question/Question';

import './FrequentlyAskedQuestions.scss';

const FrequentlyAskedQuestions = ({
    questions
}) => {

    return (
        <div className="frequently-asked-questions-wrapper">
            <div className="questions-title">Често Задавани Въпроси</div>
            <div className="questions">
                {questions.map(question => {
                    return <Question question={question.QUESTION} answear={question.ANSWEAR} />
                })}
            </div>
        </div>
    )
}

export default FrequentlyAskedQuestions;
