import React from 'react';

// Components
import CustomButton from 'components/Shared/CustomButton';

import './ContactBanner.scss';

const ContactBanner = () => {

    return (
        <div className="contact-banner-wrapper">
            <div className="contact-banner-container">
                <h1 className="title">Свържете се с нас за повече информация.</h1>
                <p className="text">"Сублиматорът за оксалова киселина от Kabo е основен инструмент за всеки пчелар. Това значително подобри здравето на моите пчели и направи пчеларството по-лесно от всякога." - Зейн Кинг</p>
                <CustomButton className="connect-button" variant="contained">Свържи се</CustomButton>
                <div className="triangles-wrapper">
                    <div className="triangle-right" />
                    <div className="triangle-left" />
                    <div className="triangle-up" />
                </div>
            </div>

            <rect className="dot-grid"></rect>
            <div className="gray-grid"></div>
        </div>
    )
};

export default ContactBanner;
