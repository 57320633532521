import React from 'react';
import { Link } from 'react-router-dom';

//Constants
import { MENU_ITEMS } from 'constants/menuConstants';

import './MenuMobile.scss';

const MenuMobile = ({
    handleItemClick
}) => {

    return (
        <div className="menu-wrapper">
            <div className="menu-items-container">
                {MENU_ITEMS.map((item, index) =>
                    <Link className="menu-item" to={item.ROUTE} key={index} onClick={handleItemClick}>
                        <span>{item.NAME}</span>
                    </Link>
                )}
            </div>
        </div>
    )
}

export default MenuMobile;
