import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Compunents
import MenuMobile from './MenuMobile/MenuMobile';
import Footer from './Footer';
import { useScreenSize } from 'utils/hooks';

// MUI
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

//Assets

//Constants
import { ROUTE } from 'constants/navigationConstants';
import { MENU_ITEMS } from 'constants/menuConstants';

import './GlobalNavigation.scss';

const GlobalNavigation = ({
    ...props
}) => {
    console.log(props);
    const { isTablet } = useScreenSize();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMobileMenuIconClick = () => {
        setIsMobileMenuOpen(currentIsOpen => !currentIsOpen);
    }

    const handleCloseMobileMenu = () => {
        setIsMobileMenuOpen(false);
    }

    return (
        <div className="application-wrapper">
            <header className="navigation-wrapper">
                <div className="logo-wrapper">
                    <Link className="logo-container" to={ROUTE.HOME_PAGE} onClick={handleCloseMobileMenu}>
                        <img src="logo.jpg" alt="Kabo logo" className="logo-image" />
                        <div className="company-name">Kabo</div>
                    </Link>
                </div>

                {!isTablet &&
                    <div className="menu-items-container">
                        {MENU_ITEMS.map((item, index) =>
                            <Link className="menu-item" to={item.ROUTE} key={index}>
                                <span>{item.NAME}</span>
                            </Link>
                        )}
                    </div>
                }

                {isTablet &&
                    <IconButton aria-label="open-sidemenu" size="small" className="sidemenu-button" onClick={handleMobileMenuIconClick}>
                        {isMobileMenuOpen ? <CloseIcon fontSize="small" /> : <MenuIcon fontSize="small" />}
                    </IconButton>
                }

            </header>

            {!isMobileMenuOpen &&
                props.children
            }

            {isTablet && isMobileMenuOpen &&
                <MenuMobile handleItemClick={handleCloseMobileMenu} />
            }

            {!isMobileMenuOpen &&
                <Footer />
            }
        </div>
    )
}

export default GlobalNavigation;
