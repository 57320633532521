import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './CustomSwiper.scss';

const CustomSwiper = ({
    images,
    ...rest
}) => {

    return (
        <div className="swiper-wrapper">
            <Swiper
                {...rest}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="custom-swiper"
            >
                {
                    images.map(image =>
                        <SwiperSlide><img alt="slide" src={image} /></SwiperSlide>
                    )
                }
            </Swiper>
        </div>

    )
};

export default CustomSwiper;
