import React from 'react';

import './CustomInforamtion.scss';

const CustomInforamtion = ({
    title,
    text
}) => {
    return (
        <div className="custom-inforamtion-wrapper">
            <h1 className="custom-inforamtion-title">{title}</h1>
            <p className="custom-inforamtion-text">{text}</p>
        </div>
    )
}

export default CustomInforamtion;
