import React from 'react';

import './CustomFeature.scss';

const CustomFeature = ({
    Icon,
    title,
    text
}) => {

    return (
        <div className="custom-feature-wrapper">
            <Icon />
            <h2 className="feature-title">
                {title}
            </h2>
            <div className="feature-text">
                {text}
            </div>
        </div>
    )
}

export default CustomFeature;
