import React from 'react';

// Components
import CustomInforamtion from 'components/Shared/CustomInforamtion';
import CustomInformationBanner from 'components/Shared/CustomInformationBanner';

// Assets
import ShopPageThird from 'assets/ShopPageThird.jpg';
import ShopPageFourth from 'assets/ShopPageFourth.jpg';

import './ProductDetails.scss';

const ProductDetails = () => {

    return (
        <div className="product-details-wrapper">
            <CustomInforamtion title="Подробности за сублиматора на оксалова киселина" text="Сублиматорът с оксалова киселина е безопасен и ефикасен метод за третиране на пчели от акари вароа. Действа чрез изпаряване на оксалова киселина, която убива акарите, без да вреди на пчелите. Този сублиматор е лесен за използване и осигурява ефективни резултати, като гарантира благосъстоянието на вашите пчели." />
            <div className="product-banners-container">
                <CustomInformationBanner image={ShopPageThird} text="Нашите сублиматори са изработени от висококачествени материали и са проектирани да издържат. Те са издръжливи, надеждни и лесни за поддръжка, което ги прави идеалният избор за пчелари с всякакво ниво на опит. С нашите експертни пчеларски ресурси ще имате цялата информация и подкрепа, от която се нуждаете, за да успеете в пчеларството."/>
                <CustomInformationBanner image={ShopPageFourth} text="Направете първата стъпка към подобряване на здравето на пчелите и защита на вашите кошери, като закупите сублиматор с оксалова киселина от Kabo. Нашият онлайн магазин предлага удобно и сигурно пазаруване. Не пропускайте този основен инструмент за пчеларите. Купете сега и започнете да се грижите за вашите пчели с увереност."/>
            </div>
        </div>
    )
}

export default ProductDetails;
